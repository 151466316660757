<template>
  <div class="" style="background:#003b5d; z-index:3003">
    <div class="d-flex justify-content-between">
        <b-button squared class="btn btn-color-sidebar" style="z-index:1001" v-b-toggle.sidebar-1>&#9776;</b-button>
       <div class="">
        <b-dropdown class="px-5 profile-btn" variant="light" style="z-index:1001;">
          <template #button-content >
            <b-icon icon="person-circle" aria-hidden="true" ></b-icon>  demo
          </template>
          <b-dropdown-item to="/dashboard/Profile" v-if="false"> Profile</b-dropdown-item>
          <b-dropdown-item v-if="false">Settings</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button variant="danger" @click="onLogout">
            <b-icon icon="box-arrow-right" aria-hidden="true" ></b-icon>
            Logout
          </b-dropdown-item-button>
        </b-dropdown>
       </div>
    </div>
    <b-sidebar  v-model="checkSidebarVisibility" id="sidebar-1" shadow width="250px" text-variant="light">
      <div class="d-flex justify-content-center mt-3 ">
      <b-img class="text-center shadow-logo rounded-circle" src="/img/logo.png"></b-img>
      </div>
      <!--<div class="text-center mt-3">
       <b-dropdown variant="light" style="z-index:1001">
          <template #button-content  >
            <b-icon icon="person-circle" aria-hidden="true" ></b-icon>  demo
          </template>
          <b-dropdown-item to="/dashboard/Profile" v-if="false"> Profile</b-dropdown-item>
          <b-dropdown-item v-if="false">Settings</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button variant="danger" @click="onLogout">
            <b-icon icon="box-arrow-right" aria-hidden="true" ></b-icon>
            Logout
          </b-dropdown-item-button>
        </b-dropdown>
      </div>-->
       <hr class="text-white">
      <div class="text-center py-1" style="font-size: 20px;">  
        <div class=" rounded-3 py-2 button-bcl " style="padding-right: 0rem !important;" data-toggle="collapse">
          <b-icon icon="layers-half" font-scale="1.5" class="text-white" aria-hidden="true"></b-icon>
          <router-link  class="text-router p-2 text-white" to="/dashboard/demo" >{{content_all[this.$store.state.languege].home}}</router-link>
        </div>
     
    </div>
     <div class="text-center py-1" style="font-size: 20px;">  
        <div class=" rounded-3 py-2 button-bcl " style="padding-right: 0rem !important;" data-toggle="collapse">
          <b-icon icon="info-circle-fill" scale="1.5" class="text-white" ></b-icon>
          <router-link v-b-modal.modal-3 class="text-router p-2 text-white" to="/dashboard/demo" >{{content_all[this.$store.state.languege].about}}</router-link>
        </div>
     
    </div>
    <div class="text-center py-1" style="font-size: 20px;">  
        <div class=" rounded-3 py-2 button-bcl " style="padding-right: 0rem !important;" data-toggle="collapse">
          <b-icon icon="file-earmark-fill" scale="1.5" class="text-white" ></b-icon>
          <router-link v-b-modal.modal-2 class="text-router p-2 text-white" to="/dashboard/demo" >{{content_all[this.$store.state.languege].reports}}</router-link>
        </div>
     
    </div>
    
    <div class="text-center py-1" style="font-size: 20px;">  
        <div class=" rounded-3 py-2 button-bcl " style="padding-right: 0rem !important;" data-toggle="collapse">
          <b-icon icon="file-text-fill" scale="1.5" class="text-white" ></b-icon>
          <router-link  class="text-router p-2 text-white" to="/dashboard/demo" >{{content_all[this.$store.state.languege].disclaimer}}</router-link>
        </div>
     
    </div>
    <div class="text-center py-5" style="font-size: 20px;"> 
    <!--<b-button v-b-modal.modal-2 variant="light" style="font-size: 14px;" block>
      
      <b-icon icon="file-earmark-fill" scale="1.5" class="text-dark" ></b-icon>
      Reports</b-button>-->
        <b-modal id="modal-2"  size="lg" title="" hide-footer style="z-index: 1050;">
          <b-overlay :show="loader" rounded="sm">
          <!--<div v-if="loader" class="d-flex align-items-center">
            <strong>Loading...</strong>
            <b-spinner class="ml-auto"></b-spinner>
          </div>-->
           <h3 class="font-weight-bold">Reports: </h3>
           <ul style="text-align: justify;">
            <li>
              <strong>Φάση 1:</strong>  Πλημμυρικός κίνδυνος ρέματος Πικροδάφνης (1/2) / Κίνδυνος πυρκαγιάς σε επιλεγμένες περιοχές του Δήμου Μαρκόπουλου Μεσογαίας / Σεισμικός κίνδυνος Δήμου Ελληνικού-Αργυρούπολης
            </li>
            <li>
              <strong>Φάση 2:</strong> Πλημμυρικός κίνδυνος ρέματος Πικροδάφνης (2/2) / Κίνδυνος πυρκαγιάς σε επιλεγμένες περιοχές του Δήμου Λαυρεωτικής / Σεισμικός κίνδυνος Δήμων Αλίμου, Γλυφάδας, Φυλής
          </li>
          <li>

            <strong>Φάση 3:</strong> Πλημμυρικός κίνδυνος ρεμάτων Σούρες & Αγ. Αικατερίνη / Κίνδυνος πυρκαγιάς σε επιλεγμένες περιοχές του Δήμου Μάνδρας-Ειδυλλίας / Σεισμικός κίνδυνος Δήμων Ηρακλείου, Νέας Ιωνίας
          </li>
              <li>

                <strong>Φάση 4:</strong> Πλημμυρικός κίνδυνος Σαρανταπόταμου / Κίνδυνος πυρκαγιάς σε επιλεγμένες περιοχές των Δήμων Αχαρνών, Σαρωνικού, Ωρωπού / Σεισμικός κίνδυνος Δήμων Αγ. Αναργύρων-Καματερού, Αγ. Βαρβάρας, Αιγάλεω, Ιλίου
            </li>
            
            <li>

              <strong>Φάση 5:</strong> Πλημμυρικός κίνδυνος Κηφισού πόταμου / Κίνδυνος πυρκαγιάς σε επιλεγμένες περιοχές των Δήμων Αγκιστρίου, Κυθήρων, Μαραθώνος, Μεγαρέων, Ραφήνας-Πικερμίου / Σεισμικός κίνδυνος Δήμων Ασπροπύργου, Αχαρνών, Ελευσίνας, Κερατσινίου-Δραπετσώνας, Κυθήρων, Μεγαρέων, Ωρωπού
            </li>

           </ul>
           <div v-for="(name,index) in reports_names[this.$store.state.languege]" :key="index" class="mt-3">
          <a @click="Readpdf(name.path)"  class="d-flex align-items-center" style="cursor:pointer">
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf-fill col-1" viewBox="0 0 16 16">
                  <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                  <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
              </svg>
                
            <p class="reports" style="margin-bottom: 0rem!important;">{{name.name}}</p>
          
          </a>
           <hr v-if="index===12">
           </div>
            <!--<div v-if="loader" class="d-flex align-items-center">
            <strong>Loading...</strong>
            <b-spinner class="ml-auto"></b-spinner>
          </div>-->
        </b-overlay>
    </b-modal>
    </div>
    <div class="text-center py-5" style="font-size: 20px;"> 
    <!--<b-button v-b-modal.modal-2 variant="light" style="font-size: 14px;" block>
      
      <b-icon icon="file-earmark-fill" scale="1.5" class="text-dark" ></b-icon>
      Reports</b-button>-->
        <b-modal id="modal-3"  size="lg" title="" hide-footer style="z-index: 1050;">
           <h3 class="font-weight-bold">{{content_all[this.$store.state.languege].about}}: </h3>
           <div v-html="content_all[this.$store.state.languege].about_content">
            



           </div>
    </b-modal>
    </div>

      <template v-slot:footer>
        
            <div class="d-flex justify-content-center p-3 align-items-center ">
              <div class="text-center px-2" style="width:14vh">
                  <img src="/img/iadet-logo.png" alt="" class="w-100">
              </div>
              <div class="text-center px-2" style="width:11vh">
                  <img src="/img/noa-logo.png" alt="" class="w-100">
              </div>
            </div>

        

      </template>

  
    </b-sidebar>
    </div>
  
</template>

<script>
import axios from '../axios-auth'
import lang from "./json/languege.json"
import reports_names from "./json/reports_names.json"

//import routes from '../router/index.js'
export default {


    data() {
        return {
            content_all:lang,
            checkSidebarVisibility: false,
            reports_names:reports_names,
            loader:false,
        }       
       

    },
    methods:{
      onLogout(){
        this.$store.dispatch('logout')
       
      },
      Readpdf(name){
        var path='reports/'+name
        console.log(path)
        this.loader=true
        axios.get('open-file/', { params: {name: path},responseType: 'blob'}).then(response => {
        //Create a Blob from the PDF Stream
        console.log(response)
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'});
        //Build a URL from the file
        //console.log(file)
            const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
            window.open(fileURL);
            this.loader=false
        })
      }
    },
}
</script>

<style>

.reports:hover {
  color: #003b5d;
}
.sidebar_footer {
    background-color: #5c5c5c;
    margin-right: 0px!important;
    padding:10px;
}
.b-sidebar {
  background: #003b5d!important;
}
.router-link-exact-active {
  color: #e9e9e9 !important;
  
}
.text-router{
  text-decoration: none;
}
.shadow-logo {
    box-shadow: 0rem 0rem 1rem rgb(255 255 255 / 18%) !important;
}
/*
.close{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(255 ,255, 255) !important;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}*/
button.close {
  color: rgb(255, 255, 255) !important;
}

.btn{
  padding: 0.675rem 1rem!important;
}
.btn-color-sidebar{
  color: #fff;
  background-color: #6c757d!important; 
  border-color: #6c757d!important;
}
.profile-btn>.btn{
  border-radius: 0rem!important;
}
</style>

